import { Provider } from "react-redux";
import RoutesConfig from "./routes/Routes";

import store from "./redux/store";
import { Suspense } from "react";

const App = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={<div className="bg-gray-900 h-screen">Loading...</div>}>
        <RoutesConfig />
      </Suspense>
    </Provider>
  );
};

export default App;
