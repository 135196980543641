// disable eslint and prettier for this file
/* eslint-disable */
/* prettier-ignore */
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { lazy, useState } from "react";
import ProtectedRoute from "./protectedRoute";
import AccessControl from "./AccessControl";

// Lazy imports for all components
const Login = lazy(() => import("../container/pages/login/Login"));
const UserGroupList = lazy(() => import("../container/pages/userGroup/UserGroupList"));
const Appointment = lazy(() => import("../container/pages/appointment"));
const ApointmentList = lazy(() => import("../container/pages/Patients/ApointmentList"));
const PatientDetails = lazy(() => import("../container/pages/Patients/PatientDetails"));
const Announcement = lazy(() => import("../container/pages/announcement/Announcement"));
const Profile = lazy(() => import("../container/pages/profile/Profile"));
const RoleList = lazy(() => import("../container/pages/role/RoleList"));
const Form_submit = lazy(() => import("../container/pages/dynamicForm/FormSubmission"));
const AddRole = lazy(() => import("../container/pages/role/AddRole"));
const AddAnnouncement = lazy(() => import("../container/pages/announcement/AddAnnouncement"));
const GlobalSettings = lazy(() => import("../container/pages/globelSettings/GlobalSettings"));
const ReferDoctor = lazy(() => import("../container/pages/referDoctor/ReferDoctor"));
const Recommendation = lazy(() => import("../container/pages/recommendation/Recommendation"));
const Prescription = lazy(() => import("../container/pages/prescription/Prescription"));
const AddPrescription = lazy(() => import("../container/pages/prescription/AddPrescription"));
const Invoices = lazy(() => import("../container/pages/invoices/Invoice"));
const AddInvoice = lazy(() => import("../container/pages/invoices/AddInvoice"));
const Users = lazy(() => import("../container/pages/users/Users"));
const NotificationList = lazy(() => import("../container/pages/notifications/NotificationList"));
const LocationList = lazy(() => import("../container/pages/location/LocationList"));
const AddLocation = lazy(() => import("../container/pages/location/AddLocation"));
const AddDoctor = lazy(() => import("../container/pages/doctor/AddDoctor"));
const DoctorList = lazy(() => import("../container/pages/doctor/DoctorList"));
const DoctorSchedule = lazy(() => import("../container/pages/doctor/schedule/Schedule"));
const AddSchedule = lazy(() => import("../container/pages/doctor/schedule/AddSchedule"));
const DynamicForm = lazy(() => import("../container/pages/dynamicForm/formBuilder"));
const MainForm = lazy(() => import("../container/pages/dynamicForm/MainForm"));
const FormList = lazy(() => import("../container/pages/dynamicForm/FormList"));
const Addpatient = lazy(() => import("../container/pages/Patients/Addpatients"));
const Viewpatient = lazy(() => import("../container/pages/Patients/Viewpatient"));
const PatientsList = lazy(() => import("../container/pages/Patients/PatientsList"));
const AddUser = lazy(() => import("../container/pages/users/AddUser"));
const AddUserGroup = lazy(() => import("../container/pages/userGroup/AddUserGroup"));
const DoctorAvailability = lazy(() => import("../container/pages/doctor/schedule/availability/Availability"));
const Payments = lazy(() => import("../container/pages/payment"));
const Opdreport = lazy(() => import("../container/pages/appointment/Opdreport"));
const Referral = lazy(() => import("../container/pages/referal/Referral"));
const Addreferral = lazy(() => import("../container/pages/referal/Addreferral"));
const GoalMaster = lazy(() => import("../container/pages/goals/GoalMaster"));
const DepartmentMaster = lazy(() => import("../container/pages/DepartmentMaster/DepartmentMaster"));
const CategoryMaster = lazy(() => import("../container/pages/CategoryMaster/CategoryMaster"));
const AgeGroupMaster = lazy(() => import("../container/pages/AgeGroupMaster/AgeGroupMaster"));
const DomainMaster = lazy(() => import("../container/pages/DomainMaster/DomainMaster"));
const AddDepartment = lazy(() => import("../container/pages/DepartmentMaster/AddDepartment"));
const AddGoal = lazy(() => import("../container/pages/goals/AddGoal"));
const AddCategory = lazy(() => import("../container/pages/CategoryMaster/AddCategory"));
const AddAgeGroup = lazy(() => import("../container/pages/AgeGroupMaster/AddAgeGroup"));
const AddDomain = lazy(() => import("../container/pages/DomainMaster/AddDomain"));
const MedicineMaster = lazy(() => import("../container/pages/MedicineMaster/MedicineMaster"));
const FormGroup = lazy(() => import("../container/pages/dynamicForm/FormGroup"));
const MedicineList = lazy(() => import("../container/pages/medicine/MedicineList"));
const MedicineForm = lazy(() => import("../container/pages/medicinePrescription/MedicineForm"));
const ShowAvailability = lazy(() => import("../container/pages/doctor/schedule/availability/Showavailability"));
const ProgramMaster = lazy(() => import("../container/pages/ProgramMaster/ProgramMaster"));
const AddProgram = lazy(() => import("../container/pages/ProgramMaster/AddProgram"));
const ProgramSession = lazy(() => import("../container/pages/ProgramSession/ProgramSession"));
const AddProgramSessions = lazy(() => import("../container/pages/ProgramSession/AddProgramSessions"));
const Accordian = lazy(() => import("../container/pages/Patients/accordian"));
const Amcat = lazy(() => import("../container/pages/amcatMaster/amcat"));
const Addamcat = lazy(() => import("../container/pages/amcatMaster/addAmcat"));
const Opd = lazy(() => import("../container/pages/Patients/opd"));
const Medicine = lazy(() => import("../container/pages/newMedicineMaster/medicine"));
const AddMedicine = lazy(() => import("../container/pages/newMedicineMaster/addMedicine"));
const Strength = lazy(() => import("../container/pages/strength/strength"));
const AddStrength = lazy(() => import("../container/pages/strength/addStrength"));
const Formulation = lazy(() => import("../container/pages/formulation/Formulation"));
const AddFormulation = lazy(() => import("../container/pages/formulation/AddFormulation"));
const MedicineName = lazy(() => import("../container/pages/medicineName/medicineName"));
const AddmedicineName = lazy(() => import("../container/pages/medicineName/AddmedicineName"));
const RouteOfAdministration = lazy(() => import("../container/pages/RouteOfAdministration/RouteOfAdministration"));
const AddRouteOfAdministration = lazy(() => import("../container/pages/RouteOfAdministration/AddRouteOfAdministration"));
const Enrolment = lazy(() => import("../container/pages/Enrolment/enrolment"));
const AddEnrolment = lazy(() => import("../container/pages/Enrolment/addEnrolment"));
const MchatForm = lazy(() => import("../container/pages/amcatMaster/MchatForm"));
const Forbidden = lazy(() => import("../components/forbidden/forbidden"));
const Homepage = lazy(() => import("../components/Homepage/Homepage"));
const RecommendationList = lazy(() => import("../container/pages/patientRecommandation/recommendationList"));
const PatientRecommendation = lazy(() => import("../container/pages/Patients/PatientRecommendation"));
const AddFollowUp = lazy(() => import("../container/pages/Patients/patient recommendation action/AddFollowUp"));
const Therapist = lazy(() => import("../container/pages/therapist"));
const Report = lazy(() => import("../container/pages/therapist/Report"));
const AddTherapist = lazy(() => import("../container/pages/therapist/AddTherapist"));
const TherepistShowAvailability = lazy(() => import("../container/pages/therapist/ShowAvailability"));
const TherapistScheduleList = lazy(() => import("../container/pages/therapist/schedule"));
const AddTherapistSchedule = lazy(() => import("../container/pages/therapist/schedule/AddTherapistSchedule"));
const TherapistAvailability = lazy(() => import("../container/pages/therapist/availability"));
const ReportPdf = lazy(() => import("../container/pages/Patients/ReportPdf"));
const AddTherapistApointment = lazy(() => import("../container/pages/therapist/AddApointment"));
const TherapistLogs = lazy(() => import("../container/pages/therapist/TherapistLogs"));
const Sessions = lazy(() => import("../container/pages/therapist/Session"));
const PatientView = lazy(() => import("../container/pages/therapist/PatientView"));
const LineChart = lazy(() => import("../components/Chart/Line"));
const CancelOPD = lazy(() => import("../container/pages/appointment/CancelOPD"));
const ActivityMaster = lazy(() => import("../container/pages/AcitivityMaster"));
const AddActivity = lazy(() => import("../container/pages/AcitivityMaster/AddActivity"));
const OPDReport = lazy(() => import("../container/pages/Reports/opdReport/opdReport"));
const PatientReport = lazy(() => import("../container/pages/Reports/patientReport/patientReport"));
const PatientAndSessionReport = lazy(() => import("../container/pages/Reports/patientAndSession/patientAndSessionReport"));
const ConsoliateReport = lazy(() => import("../container/pages/Reports/consolidatedReport/consolidatedReport"));
const TherapistEnrolment = lazy(() => import("../container/pages/therapist/Enrolement"));
const AddPlan = lazy(() => import("../container/pages/therapist/AddPlan"));
const PlanView = lazy(() => import("../container/pages/therapist/PlanView"));
const PaymentLogs = lazy(() => import("../container/pages/therapist/PaymentLog"));
const HolidayMaster = lazy(() => import("../container/pages/Holiday Master"));
const BreakTimeMaster = lazy(() => import("../container/pages/BreakTime"));
const NewReport = lazy(() => import("../container/pages/Reports/patientReport/NewReport"));
const ModuleMaster = lazy(() => import("../container/pages/Module"));
const PlanDashboardLog = lazy(() => import("../container/pages/planDashboardLog"));
const EvMaster = lazy(() => import("../container/pages/evalution/EvMaster"));
const EvDepartment = lazy(() => import("../container/pages/evalution/EvDepartment"));
const AppointmentLogs = lazy(() => import("../container/pages/therapist/AppointmentLogs"));
const TherapitPayment = lazy(() => import("../container/pages/therapist/TherapistPayment"));
const RedflagPayment = lazy(() => import("../container/pages/RedFlags/RedflagPayment"));
const ComentAndReplies = lazy(() => import("../container/pages/RedFlags/ComentAndReplies"));
const AllComments = lazy(() => import("../container/pages/RedFlags/AllComents"));
const MIS_Session_Slots = lazy(() => import("../container/pages/Reports/MIS/SessionSlots"));
const MisConversion = lazy(() => import("../container/pages/Reports/MIS/MisConversion"));
const SpecialistPerformance = lazy(() => import("../container/pages/Reports/specialist"));
const MisEnrollment = lazy(() => import("../container/pages/Reports/msi/MsiEnrollment"));
const QPRsheet = lazy(() => import("../container/pages/Reports/QPRSheet"));
const CountDown = lazy(() => import("../container/pages/therapist/CountDown"));
const CancledAppointmentSessionLog = lazy(() => import("../container/pages/therapist/CancledAppointmentSessionLog"));
const NewNewPatientReport = lazy(() => import("../container/pages/Reports/patientAndSession/PatientReport"));
const CollectionReport = lazy(() => import("../container/pages/Reports/patientAndSession/CollectionReport"));
const GetAllGoalLogs = lazy(() => import("../container/pages/allGoalLogs"));
const Genograph = lazy(() => import("../container/pages/pedigree-chart"));
const SessionsPublic = lazy(() => import("../container/pages/therapist/publicSEssion"));
const ReArange = lazy(() => import("../container/pages/AcitivityMaster/ReArange"));
const DActivityMaster = lazy(() => import("../container/pages/AcitivityMaster/Duplicates"));
const TherapistAppointmentLogs = lazy(() => import("../container/pages/therapist/TherapistAppointmentLogs"));
const UploadPedigree = lazy(() => import("../container/pages/Patients/UploadPedigree"));
const EvSessions = lazy(() => import("../container/pages/therapist/EvaluationSession"));
const CancledEvaluationSessionLog = lazy(() => import("../container/pages/therapist/CancledEvaluationSessionLog"));

const USER_ROLES = {
  SUPER_ADMIN: "SUPER_ADMIN",
  DOCTOR: "DOCTOR",
  CLINICAL_HEAD: "CLINICAL_HEAD",
  CLINICAL_ASSISTANT: "CLINICAL_ASSISTANT",
  RECEPTION_MANAGER: "RECEPTION_MANAGER",
  CLINICAL_ADMIN: "CLINICAL_ADMIN",
  PLAN_MAKER: "PLAN_MAKER",
  THERAPIST: "THERAPIST",
};

const RoutesConfig = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />{" "}
        <Route path="/public/session/:id" element={<SessionsPublic />} />{" "}
        <Route path="/chartdemo" element={<LineChart />} />
        <Route path="/unauthorized" element={<Forbidden />} />{" "}
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Homepage />} />{" "}
          <Route path="/notifications" element={<NotificationList />} />{" "}
          <Route
            element={<AccessControl allowedRoles={[USER_ROLES.SUPER_ADMIN]} />}
          // ROUTES NOT FOR DOCTOR
          >
            <Route path="/strength-master" element={<Strength />} />{" "}
            {/* <Route
              path="/therapist-appointment-logs"
              element={<TherapistAppointmentLogs />}
            />{" "} */}
            <Route path="/formulation-master" element={<Formulation />} />{" "}
            <Route path="/add-formulation">
              <Route index element={<AddFormulation />} />{" "}
              <Route path=":id" element={<AddFormulation />} />{" "}
            </Route>{" "}
            <Route path="/add-strength">
              <Route index element={<AddStrength />} />{" "}
              <Route path=":id" element={<AddStrength />} />{" "}
            </Route>{" "}
            <Route path="/medicine-name-master" element={<MedicineName />} />{" "}
            <Route path="/add-medicine-name">
              <Route index element={<AddmedicineName />} />{" "}
              <Route path=":id" element={<AddmedicineName />} />{" "}
            </Route>{" "}
            <Route
              path="/route-of-administration-master"
              element={<RouteOfAdministration />}
            />{" "}
            <Route path="/add-route-of-administration">
              <Route index element={<AddRouteOfAdministration />} />{" "}
              <Route path=":id" element={<AddRouteOfAdministration />} />{" "}
            </Route>
            <Route path="/users" element={<Users />} />{" "}
            <Route path="/locations" element={<LocationList />} />{" "}
            <Route path="/form-builder" element={<DynamicForm />}>
              <Route path=":id" element={<DynamicForm />} />{" "}
            </Route>{" "}
            <Route path="/form-submission" element={<Form_submit />}>
              <Route path=":id" element={<Form_submit />} />{" "}
            </Route>{" "}
            {/* //TODO: change routes access for activity master */}{" "}
            <Route
              path="/activity-master-duplicates"
              element={<DActivityMaster />}
            />
            <Route path="/add-activity">
              <Route index element={<AddActivity />} />{" "}
              <Route path=":id" element={<AddActivity />} />{" "}
            </Route>{" "}
            <Route path="/re-arrange" element={<ReArange />} />
            <Route path="/invoices" element={<Invoices />} />{" "}
            <Route path="/form" element={<MainForm />} />{" "}
            <Route path="/medicine-form" element={<MedicineForm />} />{" "}
            <Route path="/forms" element={<FormList />} />
            <Route path="/medicine-list" element={<MedicineList />} />
            {/* <Route path="/add-medicine" element={<AddMedicine />} /> */}
            <Route path="/global-settings" element={<GlobalSettings />} />{" "}
            <Route path="/refer-doctor" element={<ReferDoctor />} />{" "}
            <Route path="/department-master" element={<DepartmentMaster />} />{" "}
            <Route path="/category-master" element={<CategoryMaster />} />{" "}
            <Route path="/age-group-master" element={<AgeGroupMaster />} />{" "}
            <Route path="/domain-master" element={<DomainMaster />} />{" "}
            <Route path="/program-master" element={<ProgramMaster />} />{" "}
            <Route path="/program-session" element={<ProgramSession />} />{" "}
            <Route path="/recommendation" element={<Recommendation />} />{" "}
            <Route path="/prescription" element={<Prescription />} />{" "}
            <Route path="/announcement" element={<Announcement />} />{" "}
            <Route path="/user-group" element={<UserGroupList />} />{" "}
            <Route path="/mchat" element={<Amcat />} />{" "}
            <Route path="/profile" element={<Profile />} />
            <Route path="/medicine-master" element={<Medicine />} />{" "}
            <Route path="/opd" element={<Opd />} />{" "}
            <Route path="/doctors">
              <Route index element={<DoctorList />} />{" "}
              <Route path=":id/schedule">
                <Route index element={<DoctorSchedule />} />{" "}
                <Route path="add" element={<AddSchedule />} />{" "}
                <Route path="add/:scheduleId" element={<AddSchedule />} />{" "}
                <Route path=":scheduleId/availability">
                  <Route index element={<DoctorAvailability />} />{" "}
                  {/* <Route path="add" element={<AddAvailability />} /> */}{" "}
                  {/* <Route path=":availabilityId" element={<DoctorAvailability />} /> */}{" "}
                </Route>{" "}
              </Route>{" "}
            </Route>
            <Route path="mchat-form" element={<MchatForm />} />
            <Route path="/add-doctor">
              <Route index element={<AddDoctor />} />{" "}
              <Route path=":id" element={<AddDoctor />} />{" "}
            </Route>{" "}
            <Route path="/add-prescription">
              <Route index element={<AddPrescription />} />{" "}
              <Route path=":id" element={<AddPrescription />} />{" "}
            </Route>{" "}
            <Route path="/add-announcement">
              <Route index element={<AddAnnouncement />} />{" "}
              <Route path=":id" element={<AddAnnouncement />} />{" "}
            </Route>{" "}
            <Route path="/add-mchat">
              <Route index element={<Addamcat />} />{" "}
              <Route path=":id" element={<Addamcat />} />{" "}
            </Route>{" "}
            <Route path="/add-location">
              <Route index element={<AddLocation />} />{" "}
              <Route path=":id" element={<AddLocation />} />{" "}
            </Route>
            <Route path="/add-department">
              <Route index element={<AddDepartment />} />{" "}
              <Route path=":id" element={<AddDepartment />} />{" "}
            </Route>{" "}
            <Route path="/add-category">
              <Route index element={<AddCategory />} />{" "}
              <Route path=":id" element={<AddCategory />} />{" "}
            </Route>{" "}
            <Route path="/add-age-group">
              <Route index element={<AddAgeGroup />} />{" "}
              <Route path=":id" element={<AddAgeGroup />} />{" "}
            </Route>{" "}
            <Route path="/add-program-session">
              <Route index element={<AddProgramSessions />} />{" "}
              <Route path=":id" element={<AddProgramSessions />} />{" "}
            </Route>
            <Route path="/add-medicine">
              <Route index element={<AddMedicine />} />{" "}
              <Route path=":id" element={<AddMedicine />} />{" "}
            </Route>{" "}
            <Route path="/add-program">
              <Route index element={<AddProgram />} />{" "}
              <Route path=":id" element={<AddProgram />} />{" "}
            </Route>{" "}
            <Route path="/show-availability">
              <Route path=":id" element={<ShowAvailability />} />{" "}
            </Route>{" "}
            <Route path="/form-group">
              <Route path=":id" element={<FormGroup />} />{" "}
            </Route>{" "}
            <Route path="/add-domain">
              <Route index element={<AddDomain />} />{" "}
              <Route path=":id" element={<AddDomain />} />{" "}
            </Route>{" "}
            <Route path="/add-goal">
              <Route index element={<AddGoal />} />{" "}
              <Route path=":id" element={<AddGoal />} />{" "}
            </Route>{" "}
            <Route path="/add-user-group">
              <Route index element={<AddUserGroup />} />{" "}
              <Route path=":id" element={<AddUserGroup />} />{" "}
            </Route>{" "}
            <Route path="/add-invoice">
              <Route index element={<AddInvoice />} />{" "}
              <Route path=":id" element={<AddInvoice />} />{" "}
            </Route>{" "}
            <Route path="/add-user">
              <Route index element={<AddUser />} />{" "}
              <Route path=":id" element={<AddUser />} />{" "}
            </Route>{" "}
          </Route>{" "}
          <Route
            element={
              <AccessControl
                allowedRoles={[
                  USER_ROLES.SUPER_ADMIN,
                  USER_ROLES.CLINICAL_ADMIN,
                ]}
              />
            }
          >
            <Route
              path="/therapist-appointment-logs"
              element={<TherapistAppointmentLogs />}
            />
            <Route path="/activity-master" element={<ActivityMaster />} />
            <Route path="/medicine" element={<MedicineMaster />} />{" "}
            <Route path="/goal-master" element={<GoalMaster />} />{" "}
          </Route>{" "}
          <Route
            element={
              <AccessControl
                allowedRoles={[
                  USER_ROLES.DOCTOR,
                  USER_ROLES.THERAPIST,
                  USER_ROLES.SUPER_ADMIN,
                  USER_ROLES.CLINICAL_ASSISTANT,
                  USER_ROLES.CLINICAL_ADMIN,
                  USER_ROLES.PLAN_MAKER,
                  USER_ROLES.RECEPTION_MANAGER,
                  USER_ROLES.CLINICAL_HEAD,
                ]}
              />
            }
          >
            <Route path="/patients" element={<PatientsList />} />{" "}
            <Route path="/add-enrolment">
              <Route index element={<AddEnrolment />} />{" "}
              <Route path=":id" element={<AddEnrolment />} />{" "}
            </Route>
            <Route path="/patient-sessions">
              <Route path=":id" element={<Sessions />} />{" "}
            </Route>
            <Route path="/patient-evaluation-sessions">
              <Route path=":id" element={<EvSessions />} />{" "}
            </Route>
            {/* <Route
              path="/patient-recommendation"
              element={<PatientRecommendation />}
            />{" "} */}
          </Route>{" "}
          <Route path="/patient-form-submission" element={<Accordian />} />{" "}
          <Route path="/patient-form-submission">
            <Route index element={<Accordian />} />{" "}
            <Route path=":id" element={<Accordian />} />{" "}
          </Route>{" "}
          <Route path="/therapist-master" element={<Therapist />} />{" "}
          <Route path="/therapist-payment" element={<TherapitPayment />} />{" "}
          <Route path="/appointment-logs" element={<AppointmentLogs />} />{" "}
          <Route path="/add-therapist-appointment">
            <Route index element={<AddTherapistApointment />} />{" "}
            <Route path=":id" element={<AddTherapistApointment />} />{" "}
          </Route>{" "}
          <Route path="/therapist" element={<Report />} />{" "}
          <Route path="/therapist-enrolment" element={<TherapistEnrolment />} />{" "}
          <Route path="/add-therapist">
            <Route index element={<AddTherapist />} />{" "}
            <Route path=":id" element={<AddTherapist />} />{" "}
          </Route>{" "}
          <Route path="/therapist-logs" element={<TherapistLogs />} />{" "}
          <Route path="/opd-report" element={<Opdreport />} />{" "}
          <Route path="/countdown-logs" element={<CountDown />} />{" "}
          <Route
            path="/therapist/show-availability/:id"
            element={<TherepistShowAvailability />}
          />{" "}
          <Route
            path="/therapist/show-availability"
            element={<TherepistShowAvailability />}
          />{" "}
          <Route path="therapist/:id/schedule">
            <Route index element={<TherapistScheduleList />} />{" "}
            <Route path="add" element={<AddTherapistSchedule />} />{" "}
            <Route path="add/:scheduleId" element={<AddTherapistSchedule />} />{" "}
            <Route path=":scheduleId/therapist-availability">
              <Route index element={<TherapistAvailability />} />{" "}
              <Route
                path=":availabilityId"
                element={<TherapistAvailability />}
              />{" "}
            </Route>{" "}
          </Route>{" "}
          {/* holiday */} <Route path="/holiday" element={<HolidayMaster />} />{" "}
          <Route path="/modules" element={<ModuleMaster />} />{" "}
          <Route path="/break-time" element={<BreakTimeMaster />} />{" "}
          {/* New Report */}{" "}
          <Route path="/new-report" element={<NewReport />} /> //! Get all goals
          log
          <Route path="/get-all-goals-log" element={<GetAllGoalLogs />} />{" "}
          <Route path="/patient-view/:id" element={<PatientView />} />{" "}
          {/* Plan dashboard log */}{" "}
          <Route path="/plan-dashboard" element={<PlanDashboardLog />} />{" "}
          {/* Evaluation Master */}{" "}
          <Route path="/evaluation-master" element={<EvMaster />} />{" "}
          <Route path="/evaluation-department" element={<EvDepartment />} />{" "}
          <Route
            path="/therapist-enrolment/plan-view/:id/"
            element={<PlanView />}
          />{" "}
          <Route
            path="/therapist-enrolment/plan-view/:id/:month_id"
            element={<PlanView />}
          />{" "}
          <Route path="/patient-view/add-plan/:id" element={<AddPlan />} />
          <Route
            path="/patient-view/add-plan/:id/:month"
            element={<AddPlan />}
          />
          <Route path="/therapist-payment-logs" element={<PaymentLogs />} />{" "}
          <Route
            element={
              <AccessControl
                allowedRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.THERAPIST]}
              />
            }
          >
            <Route
              path="/therapist-appointment-logs"
              element={<TherapistAppointmentLogs />}
            />
          </Route>{" "}
          <Route
            element={
              <AccessControl
                allowedRoles={[
                  USER_ROLES.RECEPTION_MANAGER,
                  USER_ROLES.DOCTOR,
                  USER_ROLES.THERAPIST,
                  USER_ROLES.CLINICAL_ASSISTANT,
                  USER_ROLES.SUPER_ADMIN,
                  USER_ROLES.CLINICAL_ADMIN,
                  USER_ROLES.PLAN_MAKER,
                  USER_ROLES.CLINICAL_HEAD,
                ]}
              />
            }
          >
            <Route path="/patients" element={<PatientsList />} />{" "}
            <Route
              path="/patient-recommendation"
              element={<PatientRecommendation />}
            />
            <Route path="/program-session" element={<ProgramSession />} />{" "}
            <Route path="/recommendation" element={<Recommendation />} />{" "}
          </Route>{" "}
          <Route
            element={
              <AccessControl
                allowedRoles={[
                  USER_ROLES.RECEPTION_MANAGER,
                  USER_ROLES.SUPER_ADMIN,
                ]}
              />
            }
          >
            <Route path="/payments" element={<Payments />} />{" "}
          </Route>{" "}
          <Route
            element={
              <AccessControl
                allowedRoles={[
                  USER_ROLES.CLINICAL_ADMIN,
                  USER_ROLES.SUPER_ADMIN,
                ]}
              />
            }
          >
            {/* commented */}
            <Route path="/cancel-opd" element={<CancelOPD />} />{" "}
          </Route>{" "}
          <Route
            element={
              <AccessControl
                allowedRoles={[
                  USER_ROLES.DOCTOR,
                  USER_ROLES.SUPER_ADMIN,
                  USER_ROLES.CLINICAL_ADMIN,
                  USER_ROLES.CLINICAL_HEAD,
                ]}
              />
            }
          >
            <Route path="/referrals" element={<Referral />} />{" "}
            {/* // RedFlags are here */}
            <Route
              path="/redflag-comments/:comment_id"
              element={<ComentAndReplies />}
            />
            <Route path="/redflag-all-comments" element={<AllComments />} />
            <Route path="/add-referral" element={<Addreferral />} />{" "}
            <Route path="/add-referral">
              <Route index element={<Addreferral />} />{" "}
              <Route path=":id" element={<Addreferral />} />{" "}
            </Route>
          </Route>{" "}
          <Route
            element={
              <AccessControl
                allowedRoles={[
                  USER_ROLES.DOCTOR,
                  USER_ROLES.SUPER_ADMIN,
                  USER_ROLES.CLINICAL_ADMIN,
                ]}
              />
            }
          >
            <Route path="/redflag-payments" element={<RedflagPayment />} />
          </Route>{" "}
          <Route
            element={
              <AccessControl
                allowedRoles={[
                  USER_ROLES.DOCTOR,
                  USER_ROLES.CLINICAL_ASSISTANT,
                  USER_ROLES.RECEPTION_MANAGER,
                  USER_ROLES.CLINICAL_ADMIN,
                  USER_ROLES.PLAN_MAKER,
                  USER_ROLES.SUPER_ADMIN,
                  USER_ROLES.CLINICAL_HEAD,
                ]}
              />
            }
          >
            <Route path="/upload-pedigree" element={<UploadPedigree />} />{" "}
            <Route path="/appointment" element={<Appointment />} />{" "}
            <Route path="/opd-report" element={<Opdreport />} />{" "}
            <Route path="/add-follow-up/:id" element={<AddFollowUp />} />{" "}
            <Route path="/patient-apointment" element={<ApointmentList />} />{" "}
            <Route path="/patients" element={<PatientsList />} />{" "}
            <Route path="/petient-details" element={<PatientDetails />} />
            <Route path="/add-patient">
              <Route index element={<Addpatient />} />{" "}
              <Route path=":id" element={<Addpatient />} />{" "}
            </Route>{" "}
          </Route>
          <Route
            element={
              <AccessControl
                allowedRoles={[
                  USER_ROLES.DOCTOR,
                  USER_ROLES.CLINICAL_ASSISTANT,
                  USER_ROLES.RECEPTION_MANAGER,
                  USER_ROLES.CLINICAL_ADMIN,
                  USER_ROLES.PLAN_MAKER,
                  USER_ROLES.SUPER_ADMIN,
                  USER_ROLES.CLINICAL_HEAD,
                  USER_ROLES.THERAPIST,
                ]}
              />
            }
          >
            <Route path="/view-patient">
              <Route index element={<Viewpatient />} />{" "}
              <Route path=":id" element={<Viewpatient />} />{" "}
            </Route>{" "}
            <Route path="/patient-report">
              <Route index element={<ReportPdf />} />{" "}
              <Route path=":id" element={<ReportPdf />} />{" "}
            </Route>{" "}
          </Route>
          <Route path="/genogram/:id" element={<Genograph />} />
          {/* Reporting Routes Start from here */}{" "}
          <Route path="/patients-report" element={<PatientReport />} />{" "}
          <Route path="/opdreport" element={<OPDReport />} />{" "}
          <Route
            path="/patient-and-session-report"
            element={<PatientAndSessionReport />}
          />{" "}
          <Route
            path="/session-canceled-logs"
            element={<CancledAppointmentSessionLog />}
          />{" "}
          <Route
            path="/evaluation-canceled-logs"
            element={<CancledEvaluationSessionLog />}
          />{" "}
          <Route
            path="/patient-session-logs"
            element={<NewNewPatientReport />}
          />{" "}
          {/* <Route path="/patient-collection" element={<CollectionReport />} />{" "} */}
          {/* <Route path="/consolidate-report" element={<ConsoliateReport />} />{" "} */}
          <Route
            path="/mis-session-slot-report"
            element={<MIS_Session_Slots />}
          />
          <Route path="/mis-re-enrollment" element={<MisEnrollment />} />
          <Route path="/qpr-sheet/:patient_id" element={<QPRsheet />} />
          <Route
            path="/mis-session-slot-report-conversion"
            element={<MisConversion />}
          />
          <Route
            path="/specialist-performance"
            element={<SpecialistPerformance />}
          />
        </Route>{" "}
      </Routes>{" "}
    </Router>
  );
};

export default RoutesConfig;
